import React from 'react';
import ReactHtmlParser  from 'react-html-parser';
import * as firebase from 'firebase';
import './App.css';

const driver = {
    name: 'James',
    surname: 'Baloyi',
    email: 'james@landsea-shipping.co.za'
};

var signature = "<div><b>Service Agent Relations</b><br/><br/>Johannesburg, South Africa<br/><a href='https://zipi.co.za/' target='_blank'>www.zipi.co.za</a></div>";

var firebaseConfig = {
  apiKey: "AIzaSyDmxvEat9bQme3dRK5_Z79-tLWYrptYb6A",
  authDomain: "zipi-app.firebaseapp.com",
  databaseURL: "https://zipi-app.firebaseio.com",
  projectId: "zipi-app",
  storageBucket: "zipi-app.appspot.com",
  messagingSenderId: "1060972970372",
  appId: "1:1060972970372:web:787f7055d0de929a92ddd1",
  measurementId: "G-NGXNY0KN8X"
  };
firebase.initializeApp(firebaseConfig);

var selectedGroup = 'Accepted';


export default class Welcome extends React.Component{
    constructor(){
        super();
        this.state = {
            text: '',
            formatted: '',
            sendable: '',
            pending: [],
            approved: [],
            all: [],
        }
    }

    componentDidMount(){
      firebase.database().ref('drivers').on('value', data => {
        var obj = data.val();
        console.log(obj);
        var key;
        var approved = new  Array();
        var pending = new Array();
        var all = new Array();

        for(key in obj){
          var this_object = obj[key];
          var user = {
            firstName: this_object.firstName,
            surname: this_object.surname,
            email: this_object.email,
            plate: this_object.plateNum
          }
          var status = this_object.status;
          if(status == 'pending'){
            pending.push(user);
            this.setState({pending});
          }else if(status == 'accepted'){
            approved.push(user);
            this.setState({approved});
          }
          all.push(user);
          this.setState({all});
        }
      });
    }

    sendEmail(){
      if(selectedGroup == 'Accepted'){
        var approved = this.state.approved;
        this.setState({approvedLength: approved.length});
        approved.map(driver => {
          this.requestSend(driver, 'accepted');
        });

      }else if(selectedGroup == 'All'){

        var all = this.state.all;
        this.setState({approvedLength: all.length});
        all.map(driver => {
          this.requestSend(driver, 'all');
        });

      }else if(selectedGroup == 'Pending'){
        var pending = this.state.pending;
        this.setState({pendingLength: pending.length});
        pending.map(driver => {
          this.requestSend(driver, 'pending');
        });
      }
    }

    async requestSend(driver){
      var address = driver.email;
      var port = process.env.PORT || '8000';
      var raw = this.state.sendable;
      var rawb = raw.replace('Dirushen', driver.firstName);
      var rawc = rawb.replace('GL 56 RR GP', driver.plate);
      var html = rawc.replace('Pillay', driver.surname);

      console.log(html, address);

      var url = `https://zipi-app.web.app:${port}/sendMail?list=pending&target_address=${address}&html=${html}`;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () =>  {
        if(xhr.readyState ==  '4' && xhr.status == '200'){
          console.log('Successfully sent to ' + address);
        }
      }
      await xhr.send();
      var resp  = xhr.response;
      console.log(resp);
    }

    replaceElements(){
        var mapObj = {
            '#name': driver.name,
            '#surname': driver.surname,
            '\n': '<br/>',
            '#signature': signature,
            '#plate': 'GL 56 RR GP',
            '  ': ' ',
            'mr': 'Mr.',
            'Mr': 'Mr',
            'ms': 'Ms',
            'should of': "should've",
            'would of': "would've",
            'could of': "could've"
        }

        var text = this.state.text;
        var toDisplay = this.replaceAll(text, mapObj);
        this.setState({formatted: ReactHtmlParser(toDisplay)});
        this.setState({sendable:  toDisplay});
    }

    replaceAll(str,mapObj){
        var re = new RegExp(Object.keys(mapObj).join("|"),"gi");
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()];
        });
    }

    render(){
        return(
            <div className='parent'>
                <center>
                <textarea className='text-editor' placeholder='Your email goes here' onKeyUp={(event)=>{this.setState({text: event.target.value}); this.replaceElements()}}/>
                </center>
                <div className='preview'>
                <p>{this.state.formatted}</p>
                </div>

                <div className='controls'>
                <b style={{color: '#fff'}}>DO NOT HIT SEND! THIS THING IS IN PRODUCTION</b>
                <select onChange={(event)=>{selectedGroup = event.target.value; console.log(selectedGroup)}}>
                <option value='All'>All Drivers</option>
                <option value='Accepted' selected >Accepted Drivers</option>
                <option value='Pending'>Pending Acceptance</option>
                </select>

                <button className='submit' onClick={()=>{this.sendEmail()}}>Send</button>
                </div>        
            </div>
        )
    }
}